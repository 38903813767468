<!-- event section start-->
<section class="yoga event bg img-1" [ngStyle]="{'background-image': 'url(assets/images/yoga-img/schedule-bg.jpg)'}"
>
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="title title2">
                    <h2 class="text-white">event</h2>
                    <img src="assets/images/yoga-img/logo/bottom-curve.png" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-md-6">
                <div class="event-container d-flex">
                    <div class="yoga-circle">
                        <h3 class="gradient-text">03</h3>
                        <h6 class="month">Auguest</h6>
                    </div>
                    <div class="event-info center-content">
                        <h4 class="text-white address">Menla Mountain Retreat Center: Catskills, NY</h4>
                        <h6 class="text-white time">August 3, 2018 @ 8:00 am - September 30, 2019 @ 5:00 pm</h6>
                        <h6 class="text-white city"><i class="fa fa-map-marker m-r-5" aria-hidden="true"></i>Newyork</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="event-container d-flex">
                    <div class="yoga-circle">
                        <h3 class="gradient-text">03</h3>
                        <h6 class="month">March</h6>
                    </div>
                    <div class="event-info center-content">
                        <h4 class="text-white address">Menla Mountain Retreat Center: Catskills, NY</h4>
                        <h6 class="text-white time">August 3, 2018 @ 8:00 am - September 30, 2019 @ 5:00 pm</h6>
                        <h6 class="text-white city"><i class="fa fa-map-marker m-r-5" aria-hidden="true"></i>Newyork</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="event-container d-flex">
                    <div class="yoga-circle">
                        <h3 class="gradient-text">03</h3>
                        <h6 class="month">January</h6>
                    </div>
                    <div class="event-info center-content">
                        <h4 class="text-white address">Menla Mountain Retreat Center: Catskills, NY</h4>
                        <h6 class="text-white time">August 3, 2018 @ 8:00 am - September 30, 2019 @ 5:00 pm</h6>
                        <h6 class="text-white city"><i class="fa fa-map-marker m-r-5" aria-hidden="true"></i>Australia</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="event-container d-flex">
                    <div class="yoga-circle">
                        <h3 class="gradient-text">03</h3>
                        <h6 class="month">Jun</h6>
                    </div>
                    <div class="event-info center-content">
                        <h4 class="text-white address">Menla Mountain Retreat Center: Catskills, NY</h4>
                        <h6 class="text-white time">August 3, 2018 @ 8:00 am - September 30, 2019 @ 5:00 pm</h6>
                        <h6 class="text-white city"><i class="fa fa-map-marker m-r-5" aria-hidden="true"></i>US</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="event-container d-flex">
                    <div class="yoga-circle">
                        <h3 class="gradient-text">03</h3>
                        <h6 class="month">July</h6>
                    </div>
                    <div class="event-info center-content">
                        <h4 class="text-white address">Menla Mountain Retreat Center: Catskills, NY</h4>
                        <h6 class="text-white time">August 3, 2018 @ 8:00 am - September 30, 2019 @ 5:00 pm</h6>
                        <h6 class="text-white city"><i class="fa fa-map-marker m-r-5" aria-hidden="true"></i>UK</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="event-container d-flex">
                    <div class="yoga-circle">
                        <h3 class="gradient-text">03</h3>
                        <h6 class="month">April</h6>
                    </div>
                    <div class="event-info center-content">
                        <h4 class="text-white address">Menla Mountain Retreat Center: Catskills, NY</h4>
                        <h6 class="text-white time">August 3, 2018 @ 8:00 am - September 30, 2019 @ 5:00 pm</h6>
                        <h6 class="text-white city"><i class="fa fa-map-marker m-r-5" aria-hidden="true"></i>India</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- event section end-->
