import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  tipo = "trimestral"

  constructor() { }

  ngOnInit(): void {

  }
  home = [
    { img: "assets/images/citec/home1.png" },
    { img: "assets/images/citec/beneficios/eventos.png" },
    { img: "assets/images/citec/beneficios/talento.png" },


  ]

  homeCarouselOptions = {
    items: 8,

    autoHeight: true,
    nav: false,
    dots: false,
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
      0: {
        items: 1,
        margin: 10
      },
      320: {
        items: 1,
        margin: 20
      },
      480: {
        items: 1,
        margin: 10
      },
      576: {
        items: 1,
        margin: 0
      },
      768: {
        items: 1,
        margin: 30
      },
      992: {
        items: 1,
        margin: 30
      },
      1200: {
        items: 1.5,
        margin: 50
      }
    }
  }
  clients = [
    { img: "assets/images/citec/socios/socios-slide1.jpeg" },
    { img: "assets/images/citec/socios/slide2.jpg" },
    { img: "assets/images/citec/socios/slide3.jpg" },


  ]

  clientcarouselOptions = {
    items: 8,

    autoHeight: true,
    nav: false,
    dots: true,
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
      0: {
        items: 1,
        margin: 10
      },
      320: {
        items: 1,
        margin: 20
      },
      480: {
        items: 1,
        margin: 10
      },
      576: {
        items: 1,
        margin: 0
      },
      768: {
        items: 1,
        margin: 30
      },
      992: {
        items: 1,
        margin: 30
      },
      1200: {
        items: 1,
        margin: 50
      }
    }
  }

  owlBeneficios = [
    {
      image: "assets/images/citec/beneficios/acceo-red.svg",
      heading: "Acceso a una amplia red de Networking",
      detail: [
        "Acceso a un canal permanente de comunicación con los CEOs de las empresas miembros de CITEC.",
        "NETWORKING CITEC. ",
        "Invitación a espacios de Networking virtuales y presenciales, en las principales ciudades del país."
      ]

    },
    {
      image: "assets/images/citec/beneficios/acceso-oportunidades.svg",
      heading: "Acceso a oportunidades de negocio",
      detail: [
        "Prioridad de participación en licitaciones públicas y privadas.",
        "Oportunidades de negocio dentro de la comunidad CITEC. ",

      ]
    },
    {
      image: "assets/images/citec/beneficios/decsuentos-exclusivos.svg",
      heading: "Descuentos exclusivos",
      detail: [
        "Descuentos en programas de formación de alto nivel para el área de tecnología. ",
        "Descuentos en servicios de la comunidad CITEC. ",

      ]
    },

  ]

  carouselBeneficios = {
    items: 4,
    margin: 24,
    nav: false,
    autoplay: true,
    slideSpeed: 700,
    paginationSpeed: 800,
    loop: false,
    dots: true,
    responsive: {
      0: {
        items: 1,
        margin: 10
      },
      600: {
        items: 2,
        margin: 10
      },
      992: {
        items: 3
      }
    }
  }
  testimonials = [
    {
      img: "assets/images/citec/testimonios/item1.png",
      comment: " “SAFI ha sido miembro de CITEC   desde el año 2005. CITEC nos ha ayudado a crecer empresarialmente e impulsar nuestra Empresa con referencia de contactos y Networking que hemos realizado con todos los miembros del Gremio.",
      comment2: "También, hemos innovado e impulsado nuestros productos gracias a los Cursos de      Capacitación y Conferencias a las que hemos asistido organizadas por la Cámara. Agradecemos a CITEC por su apoyo y organización durante todo este tiempo. Estamos muy contentos de formar parte de esta Cámara.”",
      author: "Carolina Herrera",
      company: "IDT de SAFI- Herrera Carvajal Y Asociados Cía. Ltda.",
      logoCompany: "assets/images/citec/testimonios/safi.svg"
    },
    {
      img: "assets/images/citec/testimonios/javier_apolos.png",
      comment: " “Al ser parte de la CITEC hemos logrado una mayor visibilidad de nuestra Startup, lo que nos ha permitido llegar a un mayor número de stakeholders clave dentro de nuestro sector,",
      comment2: "asimismo la CITEC nos brinda la oportunidad de acceder a varias charlas con expertos de cada sector lo que amplia nuestra visión del negocio y nuestro entorno.”",
      author: "Javier Apolo",
      company: "Gerente General de SERVICIOS MÉDICOS INMOBILIARIOS TUDOC S.A.",
      logoCompany: "assets/images/citec/testimonios/tu_doc.png"
    },
    {
      img: "assets/images/citec/testimonios/alexis_martinez.jpg",
      comment: "“Gracias a CITEC he podido conectar de forma directa",
      comment2: "con las personas detrás de las empresas más importantes de tecnología en nuestro país.” ",
      author: "Alexis Martínez",
      company: "CEO de BOU COMPANY S.A.S.",
      logoCompany: "assets/images/citec/testimonios/bou.png"
    },
    {
      img: "assets/images/citec/testimonios/andres_bastidas.jpg",
      comment: "“CITEC ha sido para Smartwork un generador de oportunidades",
      comment2: "y relaciones y un espacio de discusión entre empresas que tienen los mismos problemas y desafíos”",
      author: "Andrés Bastidas",
      company: "Gerente General de Smartwork S.A.",
      logoCompany: "assets/images/citec/testimonios/smart_work.jpeg"
    },
    {
      img: "assets/images/citec/testimonios/ernesto_kruger.jpg",
      comment: " “Para mi CITEC es el resultado de años de trabajo de la industria TI y su voz más fuerte cada día permite buscar intereses comunes",
      comment2: " del sector TI del Ecuador para crecer y me alegra apoyarlo.”",
      author: "Ernesto Kruger",
      company: "Presidente de Krugercorporation S.A.",
      logoCompany: "assets/images/citec/testimonios/kruger.svg"
    },

  ]

  testimonalCarousel = {
    items: 1,
    margin: 40,
    dots: true,
    nav: false,
    autoplay: false,
    slideSpeed: 700,
    paginationSpeed: 800,
    loop: true,


    autoHeight: true,
    responsive: {
      0: {
        items: 1,
        margin: 40
      },
      600: {
        items: 1,
        margin: 40
      },
      992: {
        items: 1,
        margin: 40
      }
    }
  }



}
