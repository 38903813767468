<!--footer start-->
<footer class="agency footer2 p-b-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-12 set-first">
                <div class="logo-sec">
                    
                    <div class="footer-contant">
                        <img src="assets/images/citec/logo-citec-ec.png" alt="logo-citec" class="img-fluid footer-logo img-to-white-citec">
                     
                  
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">BENEFICIOS</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">BENEFICIOS</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="/beneficios">REPRESENTATIVIDAD</a>
                            </li>
                            <li class="">
                                <a routerLink="/beneficios">DIFUCIÓN DE NUESTROS SOCIOS</a>
                            </li>
                            <li class="">
                                <a routerLink="/beneficios">ALIANZAS</a>
                            </li>
                            <li class="">
                                <a routerLink="/beneficios">FINANCIAMIENTO</a>
                            </li>
                            <li class="">
                                <a routerLink="/beneficios">EXPORTACIÓN DE SERVICIOS</a>
                            </li>
                            <li class="">
                                <a routerLink="/beneficios">DESCUENTOS PARA SOCIOS</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">COMUNIDAD</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">COMUNIDAD</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="/comunidad">IMPACTO</a>
                            </li>
                            <li class="">
                                <a routerLink="/comunidad">CITECh TALKS</a>
                            </li>
                          
                           
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">CAPACITACIÓN</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">CURSOS</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="/cursos">CAPACITACIONES</a>
                            </li>
                            <li class="">
                                <a routerLink="/cursos">EVENTOS DE SOCIOS</a>
                            </li>
                            <li class="">
                                <a routerLink="/cursos">PROGRAMAS DE FORMACIÓN</a>
                            </li>
                            
                         
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 set-last">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">RECURSOS</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">RECURSOS</h5>
                    <div class="footer-lists op-text">
                        <ul>
                            <li class="">
                                <a href="https://citec.com.ec/blog" target="_blank">BLOGS</a>
                            </li>
                            <li class="buttons">
                                <a routerLink="/recursos">GENERACIÓN DE DATA</a>
                            </li>
                            <li class="buttons">
                                <a routerLink="/recursos">NDA ESTÁNDAR PARA SOCIOS CITEC</a>
                            </li>
                           
                            <li class="buttons">
                                <a routerLink="/recursos">BIBLIOTECA DE EVENTOS</a>
                            </li>
                          
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!--footer end-->
      
    </div>
</footer>
<!--footer end-->
<app-tap-to-top></app-tap-to-top>

