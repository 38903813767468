<!--section 404 start-->
<app-header></app-header>
<div class="">
    <div class="section-404">
        <div class="stars">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 offset-md-2 col-12">
                        <div class="central-body">

                            <img src="../assets/images/citec/404.svg" width="50%" alt="">
                            <p class="text-parrafo text-center">
                                ¡Lo sentimos, no hemos encontrado la página!                                
                            </p>
                            <a [routerLink]="['/home']" class="btn btn-default m-t-20">
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--section 404 end-->