<header class="agency nav-abs custom-scroll">

    <div class="row">
        <div class="col">
            <nav class="m-l-30 m-r-20">
                <a routerLink="/home" class="logo-light d-inline-block m-r-auto">
                    <img src="assets/images/citec/logo-citec-ec.png" alt=""  width="200px">
                </a>
                <app-center-menu class="w-100"> </app-center-menu>

            </nav>
        </div>
    </div>

</header>