import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-yoga-copyright',
  templateUrl: './yoga-copyright.component.html',
  styleUrls: ['./yoga-copyright.component.scss']
})
export class YogaCopyrightComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
