<!--header start-->
<section class="resume header" id="header">
    <div class="header7-content">
        <div class="resume bg ">
            <div class="container text-center">
                <div class="row">
                    <div class="col-lg-10 col-md-10 offset-md-1 col-sm-12">
                        <div class="center-text">
                            <div>
                                <div class="header-sub-text ">
                                    <div class="economia">
                                        <span class="impactamos">Impactamos
                                        </span>
                                        la economía a través de la tecnología
                                    </div>

                                </div>
                                <div class="header-sub-text d-flex justify-content-center align-items-center">
                                    <div class="slide-desc text-center ">
                                        Somos la Cámara que impulsa la transformación digital, conectando la academia,
                                        el gobierno y las empresas. </div>
                                </div>
                                <div class="link-horizontal d-flex justify-content-center align-items-center">
                                    <ul>

                                        <li>
                                            <a class="btn btn-default" routerLink="/requirement-forms" >
                                                Haz negocios con nuestros socios
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--header end-->