<app-header></app-header>
<section>


    <div class="row">


        <div class="container">
            <div class="col-md-12">


                <div class="col-md-10 offset-md-1">
                    <div class="title title2">
                        <div class="text-center">
                            <h3 class="title-beneficios m-b-20">
                                Tipos de
                                <span class="title-comunidad">
                                    membresía
                                </span>
                            </h3>
                        </div>
                        <p class="text-parrafo text-center">
                            Escoge tu membresía anual o trimestral
                        </p>
                    </div>

                </div>
                <div class="box-content">
                    <div class="row" id="formContact">
                        <div class="col-md-10 offset-md-1">
                            <div class="theme-card">
                                <form class="theme-form" [formGroup]="formContact" (ngSubmit)="onSummit()">
                                    <div class="form-row">

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="nombre">NOMBRE</label>
                                                <input type="text" class="form-control" id="nombre"
                                                    [ngClass]="{ 'invalid-field': formContact.get('nombre').invalid && formContact.get('nombre').touched }"
                                                    formControlName="nombre" maxlength="50">
                                                <div class="error-message"
                                                    *ngIf="formContact.get('nombre').invalid && formContact.get('nombre').touched">
                                                    Campo obligatorio
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="email">CORREO ELECTRÓNICO</label>
                                            <input type="email" class="form-control" id="email"
                                                [ngClass]="{ 'invalid-field': formContact.get('email').invalid && formContact.get('email').touched }"
                                                formControlName="email">
                                            <div class="error-message"
                                                *ngIf="formContact.get('email').invalid && formContact.get('email').touched">
                                                Campo obligatorio

                                            </div>
                                        </div>

                                    </div>

                                    <div class="form-row">


                                        <div class="col-md-6">
                                            <label for="telf">TELÉFONO</label>
                                            <input type="text" class="form-control" id="telf"
                                                [ngClass]="{ 'invalid-field': formContact.get('telefono').invalid && formContact.get('telefono').touched }"
                                                formControlName="telefono" maxlength="30">

                                            <div class="error-message"
                                                *ngIf="formContact.get('telefono').invalid && formContact.get('telefono').touched">
                                                <span *ngIf="formContact.get('telefono').errors?.required">Campo
                                                    obligatorio.</span>
                                                <span *ngIf="formContact.get('telefono').errors?.maxlength">Debe tener
                                                    máximo 30 caracteres.</span>
                                                <span *ngIf="formContact.get('telefono').errors?.pattern">Contiene
                                                    caracteres no válidos.</span>
                                            </div>


                                        </div>
                                        <div class="col-md-6">
                                            <label for="membresia">Membresía</label>
                                            <select id="membresia" class="form-control" formControlName="membresia">
                                                <option class="form-control" value="trimestral">Trimestral</option>
                                                <option class="form-control" value="anual">Anual</option>

                                            </select>
                                            <div class="error-message"
                                                *ngIf="formContact.get('membresia').invalid && formContact.get('membresia').touched">
                                                Campo obligatorio
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="col-md-12">
                                            <label for="message">MENSAJE</label>
                                            <textarea class="form-control" rows="8"
                                                [ngClass]="{ 'invalid-field': formContact.get('mensaje').invalid && formContact.get('mensaje').touched }"
                                                formControlName="mensaje"></textarea>
                                            <div class="error-message"
                                                *ngIf="formContact.get('mensaje').invalid && formContact.get('mensaje').touched">
                                                Campo obligatorio
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row text-center">
                                        <div class="col-md-12">
                                            <br><br>
                                            <button class="btn-default primary-btn  btn-contact " [disabled]="formContact.invalid">Enviar</button>
                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




    </div>
</section>
<app-footer></app-footer>
<app-copyright></app-copyright>