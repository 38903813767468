import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { YogaComponent } from './yoga/yoga.component';
import { BeneficiosComponent } from './citec/beneficios/beneficios.component';
import { CapacitacionComponent } from './citec/capacitacion/capacitacion.component';
import { ComunidadComponent } from './citec/comunidad/comunidad.component';
import { CursosComponent } from './citec/cursos/cursos.component';
import { MembershipFormComponent } from './citec/forms/membership-form/membership-form.component';
import { RequirementFormComponent } from './citec/forms/requirement-form/requirement-form.component';
import { RecursosComponent } from './citec/recursos/recursos.component';
import { AffiliateFormComponent } from './citec/forms/affiliate-form/affiliate-form.component';
import { NotFoundComponent } from './citec/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    component: YogaComponent,
    data: {
      title: "Yoga | Unice Landing Page"
    }
    
  },
  
  {
    path: 'beneficios',
    component: BeneficiosComponent,
    data: {
      title: "CITEC | BENEFICIOS"
    }
    
  },
  {
    path: 'comunidad',
    component: ComunidadComponent,
    data: {
      title: "CITEC | COMUNIDAD"
    }
    
  },{
    path:'proteccion',
    component:CapacitacionComponent,
  },
 
  {
    path:'cursos',
    component: CursosComponent,
   
  },
  {
    path:'recursos',
    component: RecursosComponent,
   
  },
  {
    path:'requirement-forms',
    component: RequirementFormComponent,
   
  },
  {
    path:'membership/:tipo',
    component: MembershipFormComponent,
   
  },
  {
    path:'afiliacion',
    component:AffiliateFormComponent ,
   
  }
  ,
  {path: '**', component: NotFoundComponent} 
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutsRoutingModule { }
