import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss']
})
export class SlideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  headercarousel = [
    { 
      tagLine1:"Impactamos ",
      tagLine2:"la economía a través de la tecnología",
      description:"Somos la Cámara que impulsa la transformación digital, conectando la academia, el gobierno y las empresas."    
    },
   
  ]
  
  headercarouselOptions= {
    items: 1,
    margin: 0,
    autoHeight: true,
    nav: false,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: false,
  }
}
