<app-header></app-header>
<section>


    <div class="row">


        <div class="container">
            <div class="col-md-12">


                <div class="col-md-10 offset-md-1">
                    <div class="title title2">

                        <h3 class="title-beneficios m-b-20">
                            Ficha
                            <span class="title-comunidad">
                                de Requerimiento
                            </span>
                        </h3>
                    </div>
                </div>
                <div class="box-content">
                    <p class="text-parrafo text-justify m-b-10">
                        Para poder apoyarles y encontrar un proveedor de manera gratuita y de la forma más eficiente les
                        invitamos a llenar el siguiente formulario.
                    </p>
                    <p class="text-parrafo text-justify m-b-20">
                       Estaremos en contacto de las posibles oportunidades
                        de negocio. Para más información contáctese con <a href="mailto:daniela@citec.com.ec" class="text-parrafo" >daniela@citec.com.ec</a> 
                    </p>
                  
                </div>
                <div class="box-content">


                    <iframe src="https://share.hsforms.com/1HSbDXJ71RRWbBXblw2rLjw3j6a5" frameborder="0" height="1200px"
                        width="100%"></iframe>


                </div>

            </div>
        </div>




    </div>
</section>
<app-footer></app-footer>
<app-copyright></app-copyright>