import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-capacitacion',
  templateUrl: './capacitacion.component.html',
  styleUrls: ['./capacitacion.component.scss']
})
export class CapacitacionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  companyList = [
    {
      logo: "assets/images/citec/proteccion_datos/argosystem.png",
      name: "Argosystems",
      content: "Brinda soluciones y servicios al desarrollo y difusión de la tecnología informática.",
      email: "mailto:jaragon@argos.com.ec",
      whatsapp: "https://wa.me/5930987384237"

    },
    {
      logo: "assets/images/citec/proteccion_datos/avl.jpg",
      name: "AVL ABOGADOS",
      content: "Servicio legal integral basado en la ética e innovación.",
      email: "mailto:sandrade@avl.com.ec",
      whatsapp: "https://wa.me/5930983628986"

    },
    {
      logo: "assets/images/citec/proteccion_datos/barzallo.png",
      name: "Barzallo BBA Abogados",
      content: "Permite la solución de problemas en los desafíos legales y regulatorios de nuestros clientes, de una manera ágil y eficiente.",
      email: "mailto:joseluis@barzallo.com",
      whatsapp: "https://wa.me/5930995888017"

    },
    {
      logo: "assets/images/citec/proteccion_datos/borneo.png",
      name: "Borneo",
      content: "Automatización de la privacidad y seguridad de los datos en una plataforma para lograr el cumplimiento continuo y proteger contra las fugas de datos.",
      email: "mailto:cecilia.parra@borneo.io",
      whatsapp: "https://wa.me/5930998396889"

    },
    {
      logo: "assets/images/citec/proteccion_datos/bupartech.png",
      name: "Business Partner Technologies - Bupartech",
      content: "Brindamos consultoría, servicios y productos especializados, que apoyan tus iniciativas de transformación digital.",
      email: "mailto:Mlopez@bupartech.com",
      whatsapp: "https://wa.me/5930998461116"

    },
    {
      logo: "assets/images/citec/proteccion_datos/ccb-abogados.png",
      name: "CCBABOGADOS CIA. LTDA.",
      content: "Somos una familia de profesionales que ofrece servicios legales personalizados, innovadores y de alta calidad para proporcionar viabilidad legal a los negocios de nuestros clientes, identificando riesgos y generando soluciones que se ajusten a sus necesidades.",
      email: "mailto:lguerra@ccbabogados.com",
      whatsapp: "https://wa.me/5930999840649"

    },
    {
      logo: "assets/images/citec/proteccion_datos/datil.png",
      name: "Datil",
      content: "Con Dátil, tu negocio cumple automáticamente con los requerimientos técnicos de las autoridades tributarias de cada país, para facturación y contabilidad electrónica.",
      email: "mailto:eraad@datil.com",
      whatsapp: "https://wa.me/5930990289327"

    },
    {
      logo: "assets/images/citec/proteccion_datos/ecija.png",
      name: "ECIJA GPA",
      content: "Con más de 25 años de experiencia en el mercado ecuatoriano. Ha sido considerada como una de las mejores firmas de servicios legales multidisciplinarios e independientes en Ecuador.",
      email: "mailto:fgonzalezk@ecija.com",
      whatsapp: "https://wa.me/5930997099342"

    },
    {
      logo: "assets/images/citec/proteccion_datos/estay.png",
      name: "Estay Consulting",
      content: "Acompañamos a las organizaciones para gestionar la innovación y su evolución- Diseño estratégico-Ingeniería de proyectos.",
      email: "mailto:christian@estayconsulting.com",
      whatsapp: "https://wa.me/5930984827808"

    },
    {
      logo: "assets/images/citec/proteccion_datos/gms.png",
      name: "GMS",
      content: "GMS una empresa regional enfocada en Seguridad de la Información. Tenemos la misión de aportar a la mejora continua en la mitigación de ciber riesgos en empresas e instituciones de la región andina que tienen como imperativo a la protección de su información.",
      email: "mailto:christian@estayconsulting.com",
      whatsapp: "https://wa.me/5930984827808"
    },
 
    {
      logo: "assets/images/citec/proteccion_datos/heka.png",
      name: "HEKA TECH",
      content: "Nuestros Servicios incluyen: legal, sistemas informáticos, software y Gobernanza.",
      email: "mailto:ateran@heka.com.ec",
      whatsapp: "https://wa.me/5930984584552"
    },

    {
      logo: "assets/images/citec/proteccion_datos/innova_consulting.png",
      name: "Innova consulting",
      content: "Somos un socio tecnológico de referencia para los servicios de Pega y Microsoft.",
      email: "mailto:Ventas@innovaconsulti.com",
      whatsapp: "https://wa.me/5930984729277"
    },
    {
      logo: "assets/images/citec/proteccion_datos/iure.png",
      name: "IURE NOVUM",
      content: "Ofrece un servicio integral de asesoría jurídica abarcando todos los temas de su interés con la finalidad de solventar todas sus inquietudes.",
      email: "mailto:ace@iurenovum.com",
      whatsapp: "https://wa.me/593990938575"
    },
    {
      logo: "assets/images/citec/proteccion_datos/jelou.png",
      name: "Jelou S.A.",
      content: "E-commerce y servicios financieros vía chats con IA",
      email: "mailto:alberto@jelou.ai",
      whatsapp: "https://wa.me/5930986401199"
    },
    {
      logo: "assets/images/citec/proteccion_datos/kruger.png",
      name: "Kruger Corp",
      content: "Es la aceleradora de negocios digitales de Kruger que busca ampliar el ecosistema emprendedor digital y facilitar el financiamiento para startups de Latinoamérica.",
      email: "mailto:peter@kruger.law",
      whatsapp: "https://wa.me/5930997014603"
    },
    {
      logo: "assets/images/citec/proteccion_datos/kyrios.jpg",
      name: "Kyrios Technologies",
      content: "Especialistas en Transformación Digital",
      email: "mailto:lalmeida@kyrios.ec",
      whatsapp: "https://wa.me/5930995142505"
    },
    {
      logo: "assets/images/citec/proteccion_datos/bodero.png",
      name: "M. Bodero y Asociados | Abogados",
      content: "Eficiencia y expertise en derecho corporativo, derecho de inversiones, resolución de conflictos y derecho público.",
      email: "mailto:jpmendoza@boderoyasociados.com",
      whatsapp: "https://wa.me/5930995933105"
    },
    {
      logo: "assets/images/citec/proteccion_datos/masterbase.png",
      name: "MasterBase",
      content: "Somos un equipo multidisciplinario compuesto por profesionales de las áreas tecnológica, de marketing y comunicaciones.Especialistas en la automatización de procesos que transformará nuestra forma de trabajar.",
      email: "mailto:enrique.zegers@gmail.com",
      whatsapp: "https://wa.me/593096099660"
    },
    {
      logo: "assets/images/citec/proteccion_datos/mivilsoft.png",
      name: "Mivilsoft",
      content: "Es una empresa consolidada en desarrollo de sistemas informáticos y electrónicos, apuntando a solventar necesidades y problemas cotidianos con propuestas tecnológicas escalables de alta calidad.",
      email: "mailto:ventas1@mivilsoft.com",
      whatsapp: "https://wa.me/5930999165725"
    },
    {
      logo: "assets/images/citec/proteccion_datos/movistar.png",
      name: "Movistar Empresas",
      content: "Soluciones y servicios gestionados donde la conectividad y la nube se unen para transformar su negocio.",
      email: "mailto:gustavo.santoslogrono@telefonica.com",
      whatsapp: "https://wa.me/5930984602350"
    },
    {
      logo: "assets/images/citec/proteccion_datos/onducloud.png",
      name: "OndúCloud",
      content: "Ondú Cloud Ofrece servicios de Cloud, TI y ciberseguridad de una manera diferente. Generando empatía y sintonía con compromiso, flexibilidad, creatividad y objetivos claros.",
      email: "mailto:cbruno@ondu.com.ec",
      whatsapp: "https://wa.me/593998307111"
    },
    {
      logo: "assets/images/citec/proteccion_datos/niubox.png",
      name: "Niubox",
      content: "Niubox es una firma pionera en asesoría legal especializada en tecnología y negocios digitales, asuntos públicos y regulatorios e innovación legal en Perú y Ecuador.",
      email: "mailto:dalvarez@niubox.legal",
      whatsapp: "https://wa.me/5930995270249"
    },
    {
      logo: "assets/images/citec/proteccion_datos/redpatner.png",
      name: "RedPartner",
      content: "Software corporativo",
      email: "mailto:Luis.calero@red-partner.com",
      whatsapp: "https://wa.me/5930992504000"
    }, 
    {
      logo: "assets/images/citec/proteccion_datos/robalino.png",
      name: "Robalino Abogados",
      content: "Una firma lider en servicios legales y de consultoria con experiencia y reconocimiento nacional e internacional.",
      email: "mailto:pcordova@robalinolaw.com",
      whatsapp: "https://wa.me/5930982647371"
    }, 
    {
      logo: "assets/images/citec/proteccion_datos/smartcidi.png",
      name: "SMARTCIDI",
      content: "Sistema de gestión",
      email: "mailto:avillavicencio@smartcidi.com",
      whatsapp: "https://wa.me/5930999723828"
    },
    {
      logo: "assets/images/citec/proteccion_datos/socio.png",
      name: "SOCIO TI - Acompañándote al Futuro",
      content:"Proyectos llave en mano que incluyen sistemas informáticos, soporte legal, consultoría, procesos.",
      email: "mailto:salvador.orellana@socioti.ec",
      whatsapp: "https://wa.me/5930987349071"
    },
    {
      logo: "assets/images/citec/proteccion_datos/spingarn.png",
      name: "Spingarn",
      content:"Somos un equipo de especialistas en consultoría corporativa. Nuestros abogados, profesionales financieros, contables y de negocios cuentan con la experiencia necesaria para acompañar a transformar sus desafíos en oportunidades. Su éxito es el parámetro de proyección de nuestro propio desempeño.",
      email: "mailto:lnaranjo@spingarn.ec",
      whatsapp: "https://wa.me/59322554473"
    },
    {
      logo: "assets/images/citec/proteccion_datos/territorios_int.png",
      name: "Territorios Inteligentes iT Cía. Ltda.",
      content:"ERP Gubernamentales / Sistemas Sector Público en general.",
      email: "mailto:aochoa@territoriosinteligentes.net",
      whatsapp: "https://wa.me/5930979367333"
    },
    {
      logo: "assets/images/citec/proteccion_datos/todolegal.png",
      name: "TodoLegal SAS",
      content:"Desde el 2015, Todo.Legal se inició como una firma tecnológica especializada en servicios legales, ofreciendo soluciones de firma electrónica basadas en la nube, así como un ecosistema digital para habilitar la Automatización de Procesos (BPA).",
      email: "mailto:paul.quinonez@todolegal.com",
      whatsapp: "https://wa.me/5930990662899"
    },
    {
      logo: "assets/images/citec/proteccion_datos/vimework.png",
      name: "Vimeworks Cia. Ltda.",
      content:"Vimeworks es una empresa ecuatoriana con más de 20 años de experiencia en brindar soluciones de desarrollo de software a la medida de forma innovadora, ágil y con estándares que mejoran la operatividad y competitividad de nuestros clientes.",
      email: "mailto:msantacruz@vimeworks.com",
      whatsapp: "https://wa.me/5930999704496"
    }


  ]


}


