<div style="padding: 0px !important; margin-bottom: 44px;">


    <div class="bg-margin">
        <div class="container-fluid bg-image" style="background-image: url(assets/images/citec/group126.svg);">
            <div class="row align-items-center">
                <div class="col-12 text-center">
                    <h1 class="text-center title-afiliacion">
                        Sé parte de la CITEC </h1>
                    <div class="col-sm-12 col-md-6  col-lg-6 offset-lg-3  offset-md-3 text-center">
                        <p class="text-white container-text">Y genera impacto, con tu conocimiento y el de tus
                            colaboradores, en el ecosistema digital del Ecuador</p>
                    </div>

                    <div class="m-t-40 mb-bt-afiliacion0">
                        <a routerLink="/afiliacion" class="btn btn-white ">
                            INICIAR PROCESO DE AFILIACIÓN
                        </a>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>