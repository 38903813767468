import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { YogaComponent } from './layouts/yoga/yoga.component'
import { CitecComponent } from './layouts/citec/citec.component';
export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: CitecComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
