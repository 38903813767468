import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SwiperModule } from 'swiper/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module'
import { CountToModule } from 'angular-count-to';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng5SliderModule } from 'ng5-slider';

//Yoga Layout
import { YogaComponent } from './yoga/yoga.component';
import { YogaNavComponent } from './yoga/yoga-nav/yoga-nav.component';
import { YogaHeaderComponent } from './yoga/yoga-header/yoga-header.component';
import { YogaExperienceComponent } from './yoga/yoga-experience/yoga-experience.component';
import { YogaScheduleComponent } from './yoga/yoga-schedule/yoga-schedule.component';
import { YogaExpertComponent } from './yoga/yoga-expert/yoga-expert.component';
import { YogaTestimonialComponent } from './yoga/yoga-testimonial/yoga-testimonial.component';
import { YogaPricingComponent } from './yoga/yoga-pricing/yoga-pricing.component';
import { YogaSubscribeComponent } from './yoga/yoga-subscribe/yoga-subscribe.component';
import { YogaBlogComponent } from './yoga/yoga-blog/yoga-blog.component';
import { YogaEventComponent } from './yoga/yoga-event/yoga-event.component';
import { YogaBrandComponent } from './yoga/yoga-brand/yoga-brand.component';
import { YogaCopyrightComponent } from './yoga/yoga-copyright/yoga-copyright.component';
import { ComunidadComponent } from './citec/comunidad/comunidad.component';
import { CapacitacionComponent } from './citec/capacitacion/capacitacion.component';
import { CursosComponent } from './citec/cursos/cursos.component';
import { RecursosComponent } from './citec/recursos/recursos.component';
import { CopyrightComponent } from './citec/copyright/copyright.component';
import { RequirementFormComponent } from './citec/forms/requirement-form/requirement-form.component';
import { MembershipFormComponent } from './citec/forms/membership-form/membership-form.component';
import { AfiliacionComponent } from './citec/afiliacion/afiliacion.component';
import { CitecComponent } from './citec/citec.component';
import { HomeComponent } from './citec/home/home.component';
import { BeneficiosComponent } from './citec/beneficios/beneficios.component';
import { HeaderComponent } from './citec/header/header.component';
import { FooterComponent } from './citec/footer/footer.component';
import { SlideComponent } from './citec/slide/slide.component';
import { AffiliateFormComponent } from './citec/forms/affiliate-form/affiliate-form.component';
import { NotFoundComponent } from './citec/not-found/not-found.component';
import { ToastrModule } from 'ngx-toastr';
@NgModule({
  declarations: [
    YogaComponent,
    YogaNavComponent,
    YogaHeaderComponent,
    YogaExperienceComponent,
    YogaScheduleComponent,
    YogaExpertComponent,
    YogaTestimonialComponent,
    YogaPricingComponent,
    YogaSubscribeComponent,
    YogaBlogComponent,
    YogaEventComponent,
    YogaBrandComponent,
    YogaCopyrightComponent,
    CitecComponent,
    HomeComponent,
    BeneficiosComponent,
    HeaderComponent,
    FooterComponent,
    SlideComponent,
    ComunidadComponent,
    CapacitacionComponent,
    CursosComponent,
    RecursosComponent,
    CopyrightComponent,
    RequirementFormComponent,
    MembershipFormComponent,
    AfiliacionComponent,
    AffiliateFormComponent,
    NotFoundComponent],

  imports: [
    CommonModule,
    LayoutsRoutingModule,
    SwiperModule,
    CarouselModule,
    NgbModule,
    SharedModule,
    CountToModule,
    FormsModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    ToastrModule.forRoot(),
  ],

  exports: [],
})

export class LayoutsModule { }
