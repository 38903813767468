<app-header></app-header>
<section>



        <div class="col-md-12 m-l-50 m-r-50">
            <div class="text-center margin-comuni">
                <h3 class="title-beneficios m-b-50 ">Comunidad <span class="title-comunidad">
                        que fomenta la transformación digital en todo el país.</span>
                </h3>
            </div>

        </div>

        <div class="container">
            <div class="col-md-12">
                <div class="text-center">
                    <img src="assets/images/citec/comunidad/comunidad.svg" class="img-fluid" alt="">
                </div>

                <div class="col-md-8 offset-md-2">
                    <div class="title title2">
                        <h3 class="subtitle-content m-b-50 m-t-50">Comunidad</h3>

                    </div>
                </div>
                <div class="box-content">
                    <p class="text-parrafo">
                        Fomentamos el aumento de oferta de talento humano digital, así como la mejora de su formación e
                        inserción en el mundo laboral.

                    </p>

                    <p class="text-parrafo m-b-30">
                        Trabajamos en conjunto con las entidades rectoras de educación en el Ecuador para potenciar el
                        currículo y aportar al desarrollo del talento digital.

                    </p>
                    <p class="text-parrafo m-b-30">
                        Generamos acciones para reducir la brecha de género en el sector TECH.

                    </p>
                    <p class="text-parrafo m-b-30">
                        Generamos espacios de diálogo sobre tecnología, en los que participan líderes del sector,
                        enfocados a democratizar el conocimiento de las herramientas tecnológicas.

                    </p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="col-md-12">
                <div class="title title2 text-center">
                    <h3 class="subtitle-content m-b-20 m-t-50">CITECh Talks</h3>
                </div>
                <div class="box-content">
                    <p class="text-parrafo">
                        Una vez al mes nos reunimos con nuestros socios, líderes del sector, en un espacio de diálogo,
                        donde ponemos la tecnología al alcance de todos, en un ambiente amigable y de fácil comprensión.
                        Este encuentro se ha posicionado y ha tenido mucha acogida por parte de los espectadores con
                        vistas promedio de 20 mil personas.
                    </p>
                </div>
            </div>
        </div>
        <div class="container m-t-50" id="talk">
            <div class="row">
                <div class="col-md-10 offset-md-1 col-sm-10 text-center offset-sm-1">
                    <owl-carousel-o [options]="carouselTalk">
                        <ng-container *ngFor="let expert of talk" style="height:420px !important; width: auto;">
                            <ng-template carouselSlide class="item">
                                <div class="d-flex justify-content-center align-items-center">
                                    <img [src]="expert.img" alt="" style="height:420px !important; width: auto;"
                                        class="img-fluid">
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>

                </div>
            </div>
        </div>
       <!--  <div class="container">
            <div class="col-md-12">
                
                <div class="title title2 text-center">
                    <h3 class="subtitle-content m-b-0 m-t-50">Eventos de Networking</h3>
                </div>
                <div class="box-content">
                    <p class="text-parrafo">
                        Generamos encuentros presenciales y virtuales de networking con diferentes metodologías a nivel nacional para consolidar las relaciones entre nuestros asociados y con organizaciones externas al gremio y poder conectar oferta y demanda.
                    </p>
                </div>
                <div class="text-center">
                    <img src="assets/images/citec/comunidad/eventos.png" class="img-fluid" width="50%" alt="">
                </div>
            </div>
        </div> -->

</section>
<app-afiliacion></app-afiliacion>
<app-footer></app-footer>
<app-copyright></app-copyright>