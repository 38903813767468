<app-header></app-header>
<section>


    <div class="row">


        <div class="container">
            <div class="col-md-12">


                <div class="col-md-8 offset-md-2">
                    <div class="title title2">

                        <h3 class="title-beneficios m-b-20">
                            Solicitud
                            <span class="title-comunidad">
                                de socio
                            </span>
                        </h3>
                    </div>
                </div>
                <div class="box-content">
                    <p class="text-parrafo ">
                        Para iniciar con la afiliación, complete este formulario y empezaremos el proceso. Si requiere mayor información comunícate a: <a class="text-parrafo" href="mailto: info@citec.com.ec"> info@citec.com.ec</a>
                    </p>
                    <br>

                    <p style="color:#5200ff;" class="text-parrafo text-center m-b-20">El período mínimo de afiliación a CITEC es 3 meses</p>
                </div>
                <div class="box-content">

            
                    <iframe 
                    src="https://share.hsforms.com/11vyVC1hRRK-Iph0HF6Fu9A3j6a5" 
                    frameborder="0" 
                    height="1200px"
                    width="100%"
                    ></iframe>

                 
                </div>

            </div>
        </div>




    </div>
</section>
<app-footer></app-footer>
<app-copyright></app-copyright>