<app-header></app-header>
<section>
    <div class="col-md-12">
        <div class="text-center my-beneficio">
            <h3 class="title-beneficios">Beneficios <span class="title-comunidad">de la <br> comunidad
                    CITEC</span>
            </h3>
        </div>
    </div>
    <div class="container">
        <div class="col-md-12">
            <div class="text-center m-t-50 m-b-50">
                <img src="assets/images/citec/beneficios/beneficios.svg" class="img-fluid" alt="">
            </div>

            <div class="col-md-8 offset-md-2">
                <div class="title title2">
                    <h3 class="subtitle-content m-b-50">Representación gremial</h3>

                </div>
            </div>
            <div class="box-content">
                <p class="text-parrafo">
                    Impulsamos los intereses del sector a nivel regulatorio, mediante acciones coordinadas con actores
                    claves en el proceso de toma de decisiones.
                </p>
                <p class="bold-content m-t-30 m-b-30">
                    Para lograrlo:
                </p>
                <p class="text-parrafo m-b-30">
                    -Trabajamos en la elaboración de políticas públicas que promuevan el desarrollo e implementación de
                    nuevas tecnologías, en beneficio de la transformación digital del país.
                </p>
                <p class="text-parrafo m-b-30">
                    -Participamos en comparecencias y reuniones con las distintas carteras de Estado, para el
                    tratamiento de proyectos legislativos que inciden en el sector.
                </p>
                <p class="text-parrafo m-b-30">
                    -Trabajamos internamente en la Comisión Regulatoria, en dónde, con los aportes de socios expertos en
                    Legal Tech, se generan acciones que inciden en la propuesta, desarrollo e implementación de
                    normativa y políticas públicas que afectan al ámbito tecnológico.
                </p>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="col-md-12">
            <div class="text-center">
                <img src="assets/images/citec/beneficios/difusion.svg" class="img-fluid" alt="">
            </div>

            <div class="col-md-8 offset-md-2">
                <div class="title title2">
                    <h3 class="subtitle-content m-b-50">Difusión y visibilización de
                        nuestros socios</h3>

                </div>
            </div>
            <div class="box-content">
                <p class="text-parrafo m-b-30">
                    A través de nuestros canales de comunicación internos: mailing y chat de socios y externos: página
                    web, boletín informativo, Facebook, Instagram, LinkedIn, Twitter, YouTube nuestros socios tienen la
                    posibilidad de dar a conocer sus iniciativas, logros y acciones relevantes de sus empresas.

                </p>

                <p class="text-parrafo m-b-30">

                    De igual forma, nuestros socios trabajan de manera colaborativa en conjunto con la CITEC para llevar
                    a cabo eventos presenciales y virtuales, ferias, webinars, seminarios, talleres y más, sobre temas
                    importantes para el ecosistema tecnológico y de innovación.

                </p>
                <p class="text-parrafo m-b-30">
                    Como CITEC contamos con el catálogo de servicios de nuestros socios, que trabajamos por posicionar
                    como la fuente principal de búsqueda de soluciones tecnológicas del país.
                </p>

            </div>
        </div>
    </div>

    <div class="container">
        <div class="col-md-12">


            <div class="col-md-12">
                <div class="title title2 text-center">
                    <h3 class="subtitle-content m-b-50 m-t-50">Alianzas y partners</h3>

                </div>
            </div>
            <div class="box-content ">
                <p class="text-parrafo m-b-30">
                    Sabemos que unir fuerzas con otros actores del ecosistema es la mejor forma de generar mayor
                    impacto. Es por esto que para CITEC, nuestros aliados son parte fundamental del desarrollo de
                    actividades, proyectos y consecución de objetivos. Por esta razón, buscamos activamente crear nuevos
                    espacios de colaboración con otras instituciones de diferentes sectores que puedan aportar a la
                    dinamización de nuestra industria.

                </p>
                <div class="col-md-12">
                    <img src="assets/images/citec/beneficios/alianzas.jpg" alt="" class="img-fluid">

                </div>
            </div>

        </div>
    </div>
    <div class="container">
        <div class="col-md-12">
            <div class="text-center">
                <img src="assets/images/citec/beneficios/internacionalizacion.svg" class="img-fluid" alt="">
            </div>

            <div class="col-md-8 offset-md-2">
                <div class="title title2">
                    <h3 class="subtitle-content m-b-50 m-t-50">Internacionalización</h3>

                </div>
            </div>
            <div class="box-content">
                <p class="text-parrafo m-b-30">
                    Gestionamos de manera colaborativa la implementación de estrategias y acciones enfocadas en que las
                    empresas tecnológicas y de innovación del Ecuador puedan exportar sus soluciones y servicios.
                </p>

                <p class="text-parrafo m-b-30">
                    Además, acompañamos a las empresas internacionales durante su proceso de ingreso al país.
                </p>
                <p class="text-parrafo">
                    Difundimos entre nuestros socios: oportunidades para participar en viajes con diferentes objetivos
                    como:

                </p>
                <p class="bold-content-inter m-t-30 m-b-30">
                    Ferias Internacionales:
                </p>
                <p class="text-parrafo">
                    Para establecer redes de contactos globales con profesionales y empresas de la industria, explorar
                    nuevas oportunidades de negocios y mercados internacionales.

                </p>
                <p class="bold-content-inter m-t-30 m-b-30">
                    Misiones Exploratorias o de Inmersión:
                </p>
                <p class="text-parrafo">
                    Para poder facilitar la investigación y el descubrimiento de nuevas tecnologías y tendencias
                    emergentes, permitir identificar oportunidades de innovación y desarrollo en el mercado tecnológico.
                </p>
                <p class="bold-content-inter m-t-30 m-b-30">
                    Misiones Comerciales:
                </p>
                <p class="text-parrafo">
                    Con el objetivo de explorar y acceder a nuevos mercados internacionales para expandir el negocio y
                    facilitar el establecimiento de relaciones comerciales y contactos clave en el extranjero. Ayudar a
                    promover la imagen de Ecuador y sus empresas a nivel internacional y a aumentar su visibilidad
                    global. Expandimos a más de 25 empresas ecuatorianas a destinos llamativos a nivel internacional en
                    la industria tecnológica y de innovación.
                </p>
                <p class="bold-content-inter m-t-30 m-b-30">
                    Rondas de Inversión:
                </p>
                <p class="text-parrafo">
                    Las rondas de inversión son etapas en las que una empresa en crecimiento busca financiamiento
                    externo de inversores para impulsar su desarrollo.
                </p>

            </div>
        </div>
    </div>
    <div class="container">
        <div class="col-md-12 m-t-50">
            <div class="text-center">
                <img src="assets/images/citec/beneficios/descuentos.svg" class="img-fluid" alt="">
            </div>

            <div class="col-md-8 offset-md-2">
                <div class="title title2">
                    <h3 class="subtitle-content m-b-30 m-t-50">Descuentos para socios</h3>

                </div>
            </div>
            <div class="box-content">
                <p class="text-parrafo m-b-30">
                    Los socios CITEC pueden acceder a nuestros programas de capacitación con un costo preferencial.
                    Además, acceder a descuentos exclusivos que otorgan nuestros aliados y socios en temas de formación
                    y soluciones.
                </p>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="col-md-12">
            <div class="text-center">
                <img src="assets/images/citec/beneficios/accesored.svg" class="img-fluid" alt="">
            </div>
            <div class="col-md-8 offset-md-2">
                <div class="title title2">
                    <h3 class="subtitle-content m-b-40 m-t-40">
                        Acceso a una amplia red de Networking
                    </h3>
                </div>
            </div>
            <div class="box-content">
                <p class="text-parrafo m-b-30">
                    Pertenecer a la comunidad CITEC significa estar conectado de manera permanente con los líderes del
                    sector. Contamos con un chat activo con los principales representantes de las empresas socias en
                    dónde están continuamente enviando información relevante.
                </p>

            </div>
        </div>
    </div>
    <div class="container">
        <div class="col-md-12">
            <div class="text-center">
                <img src="assets/images/citec/beneficios/data.png" class="img-fluid" alt="" width="55%">
            </div>
            <div class="col-md-8 offset-md-2">
                <div class="title title2">
                    <h3 class="subtitle-content m-b-40 m-t-40">
                        Data
                    </h3>
                </div>
            </div>
            <div class="box-content">
                <p class="text-parrafo m-b-30">
                    Trabajamos para levantar datos y difundir información relevante del sector tecnológico y de
                    innovación con el propósito de que nuestros socios puedan tomar decisiones estratégicas que aporten
                    al desarrollo de sus empresas y de la industria. Generamos estudios, publicaciones, infografías y
                    artículos sobre el ecosistema de exclusivo acceso para los socios.
                </p>

            </div>
        </div>
    </div>
    <div class="container">
        <div class="col-md-12">
            <div class="text-center">
                <img src="assets/images/citec/beneficios/talento.png" class="img-fluid" alt="" width="55%">
            </div>
            <div class="col-md-8 offset-md-2">
                <div class="title title2">
                    <h3 class="subtitle-content m-b-40 m-t-40">
                        Talento Humano
                    </h3>
                </div>
            </div>
            <div class="box-content">
                <p class="text-parrafo m-b-30">
                    Enfocamos nuestros esfuerzos en:
                </p>
                <p class="text-parrafo m-b-30">
                    -Potenciar las habilidades de los equipos de las empresas asociadas
                </p>
                <p class="text-parrafo m-b-30">
                    -Aportar a las líneas tradicionales de formación fortaleciendo los lazos con la Academia para
                    implementar habilidades digitales
                </p>
                <p class="text-parrafo m-b-30">
                    -Generar mediante programas eficientes de formación, el aumento de la cantidad de talento humano
                    digital capacitado para las empresas del sector.
                </p>
                <p class="text-parrafo m-b-30">
                    -Ser el punto focal de conexión entre la oferta y demanda.
                </p>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="col-md-12">
            <div class="text-center">
                <img src="assets/images/citec/beneficios/oportunidad.png" class="img-fluid" alt="" width="35%">
            </div>
            <div class="col-md-8 offset-md-2">
                <div class="title title2">
                    <h3 class="subtitle-content m-b-40 m-t-40">
                        Acceso a oportunidades de negocio
                    </h3>
                </div>
            </div>
            <div class="box-content">
                <p class="text-parrafo m-b-30">
                    Socializamos requerimientos que nos envían desde organizaciones públicas y privadas para que
                    nuestros socios puedan generar nuevos negocios.
                </p>

            </div>
        </div>
    </div>
    <div class="container">
        <div class="col-md-12">
            <div class="text-center">
                <img src="assets/images/citec/beneficios/eventos.png" class="img-fluid" alt="" width="55%">
            </div>
            <div class="col-md-8 offset-md-2">
                <div class="title title2">
                    <h3 class="subtitle-content m-b-40 m-t-40">
                        Acceso a beneficios en eventos exclusivos
                    </h3>
                </div>
            </div>
            <div class="box-content">
                <p class="text-parrafo m-b-30">
                    Nuestra agenda se encuentra llena de encuentros importantes del sector. Los socios CITEC pueden
                    acceder a ellos mediante beneficios que les otorgan por ser parte del gremio. 
                </p>

            </div>
        </div>
    </div>

</section>
<app-afiliacion></app-afiliacion>
<app-footer></app-footer>
<app-copyright></app-copyright>