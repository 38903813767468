<app-header></app-header>
<section>
    <div class="row">
        <div class="col-md-12">
            <div class="text-center">
                <h3 class="title-beneficios m-b-50">Cursos gratuitos
                </h3>
            </div>
        </div>
        <div class="container">
            <div class="col-md-12">
                <div class="text-center">
                    <img src="assets/images/citec/cursosgratuitos.svg" class="img-fluid" alt="">
                </div>
                <div class="col-md-8 offset-md-2">
                    <div class="title title2">
                        <h3 class="subtitle-content m-b-40 m-t-50">Formación de libre acceso</h3>
                    </div>
                </div>
                <div class="box-content">
                    <p class="text-parrafo">
                        Con el objetivo de dar un apoyo constante a la formación del talento digital en Ecuador, CITEC
                        comparte un acceso directo a una oferta amplia y variada de cursos cortos, ofertada por aliados
                        y miembros del gremio.
                    </p>
                    <p class="text-parrafo">
                        Los cursos están enfocados en desarrollar y fortalecer habilidades, tanto de principiantes, como
                        de profesionales del sector tecnológico.
                    </p>

                </div>
            </div>
        </div>
        <div class="container d-flex justify-content-center">
            <div class="row m-t-50">
                <div class="col-md-6 m-b-30  ">
                    <div class="card border-recurso">
                        <div class="card-body space-border " style="width: 400px; height: 520px;">
                            <div class=" m-b-40 text-center">
                                <img src="assets/images/citec/cursos/aws.svg" alt="">
                            </div>
                            <p class="bold-title text-left m-b-15">
                                Amazon Web Services
                            </p>
                            <div class=" m-b-25">

                                <ul>
                                    <li class="bloc-detail-course">Bases de datos </li>
                                    <li class="bloc-detail-course">Herramientas para desarrolladores </li>
                                    <li class="bloc-detail-course">Internet de las Cosas </li>
                                    <li class="bloc-detail-course">Machine learning </li>
                                    <li class="bloc-detail-course">Game Tech </li>
                                    <li class="bloc-detail-course">Análisis de datos</li>
                                </ul>
                            </div>
                            <div>
                                <p class="bloc-detail-course">
                                    UTILIZA ESTE CÓDIGO ESPECIAL: <span class="bold-title">CITEC2022AWS</span>
                                </p>
                            </div>

                        </div>
                        <div class="card-footer footer-course text-center m-b-40">
                            <a href=" https://acrobat.adobe.com/link/review?uri=urn:aaid:scds:US:0b8a77f0-0ae2-4b0b-bc0c-4abc45532fc4"
                                target="_blank" class="btn btn-default btn-membresia-anual">
                                Acceder a la plataforma
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6  m-b-30">
                    <div class="card border-recurso">
                        <div class="card-body space-border " style="width: 400px; height: 520px;">
                            <div class=" m-b-40 text-center">
                                <img src="assets/images/citec/cursos/movistar.png" alt="">
                            </div>
                            <p class="bold-title text-left m-b-15">
                                Profesionales 4.0 Fundación Telefónica
                            </p>
                            <div class=" m-b-25">

                                <ul>
                                    <li class="bloc-detail-course">Construcción 4.0</li>
                                    <li class="bloc-detail-course">Transporte 4.0</li>
                                    <li class="bloc-detail-course">Agroalimentario 4.0</li>
                                    <li class="bloc-detail-course">Turismo y Hotelería 4.0</li>
                                 

                                </ul>
                            </div>

                        </div>
                        <div class="card-footer footer-course text-center m-b-40">
                            <a href="https://conectaempleo-formacion.fundaciontelefonica.com/profesionales-4-0" target="_blank"
                                class="btn btn-default btn-membresia-anual">
                                Acceder a la plataforma
                            </a>
                        </div>

                    </div>


                </div>
            </div>
        </div>
        <div class="container d-flex justify-content-center">
            <div class="row m-t-50">
                <div class="col-md-6 m-b-30  ">
                    <div class="card border-recurso">
                        <div class="card-body space-border " style="width: 400px; height: 480px;">
                            <div class=" m-b-40 text-center">
                                <img src="assets/images/citec/cursos/ibm.svg" alt="">
                            </div>
                            <p class="bold-title text-left m-b-15">
                                IBM
                            </p>
                            <div class=" m-b-25">

                                <ul>
                                    <li class="bloc-detail-course">Inteligencia artificial </li>
                                    <li class="bloc-detail-course">Ciencia de datos </li>
                                    <li class="bloc-detail-course">Blockchain </li>
                                    <li class="bloc-detail-course">IBM Cloud</li>
                                    <li class="bloc-detail-course">Power Systems</li>
                                    <li class="bloc-detail-course">IBM Z</li>
                                    <li class="bloc-detail-course">IBM Security </li>
                                </ul>
                            </div>

                        </div>
                        <div class="card-footer footer-course text-center m-b-40">
                            <a href="https://skillsbuild.org/es" target="_blank"
                                class="btn btn-default btn-membresia-anual">
                                Acceder a la plataforma
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6  m-b-30 ">
                    <div class="card border-recurso">
                        <div class="card-body space-border" style="width: 400px; height: 480px;">
                            <div class=" m-b-40 text-center">
                                <img src="assets/images/citec/cursos/oracle.png" alt="">
                            </div>
                            <p class="bold-title text-left m-b-15">
                                ORACLE
                            </p>
                            <div class=" m-b-25">

                                <ul>
                                    <li class="bloc-detail-course">Accesibilidad y Tecnologías Inclusivas </li>
                                    <li class="bloc-detail-course"> Administración de la nube </li>
                                    <li class="bloc-detail-course"> Azure </li>


                                </ul>
                            </div>

                        </div>
                        <div class="card-footer footer-course text-center m-b-40">
                            <a href="https://education.oracle.com/learning-explorer" target="_blank"
                                class="btn btn-default btn-membresia-anual">
                                Acceder a la plataforma
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="container d-flex justify-content-center">
            <div class="row m-t-50">
                <div class="col-md-12 m-b-30  ">
                    <div class="card border-recurso">
                        <div class="card-body space-border " style="width: 400px; height: 420px;">
                            <div class=" m-b-40 text-center">
                                <img src="assets/images/citec/cursos/microsoft.svg" alt="">
                            </div>
                            <p class="bold-title text-left m-b-15">
                                Microsoft
                            </p>
                            <div class=" m-b-25">

                                <ul>
                                    <li class="bloc-detail-course">Accesibilidad y Tecnologías Inclusivas </li>
                                    <li class="bloc-detail-course">Administración de la Nube </li>
                                    <li class="bloc-detail-course">Azure</li>

                                </ul>
                            </div>

                        </div>
                        <div class="card-footer footer-course text-center m-b-40">
                            <a href="https://learn.microsoft.com/en-us/training/browse/" target="_blank"
                                class="btn btn-default btn-membresia-anual">
                                Acceder a la plataforma
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="container" id="event">
        <div class="col-md-12 m-t-50">

            <div class="title title2 text-center">
                <h3 class="subtitle-content m-b-40">
                    Eventos de nuestros socios
                </h3>

            </div>

            <div class="box-content">
                <p class="text-parrafo">
                    Difundimos y apoyamos los eventos que realizan nuestros socios para generar conocimiento en el mundo
                    TECH y de innovación.
                </p>

            </div>
        </div>
    </div>
    <div class="container m-t-50">
        <div class="row">
            <div class="col-md-4 offset-md-4 justify-content-center text-center">
                <iframe src="https://www.instagram.com/p/CpwA9sUOESM/embed/" width="320" height="480" frameborder="0"
                    scrolling="no" allowfullscreen></iframe>

            </div>
        </div>


    </div>
    <div class="container" id="bootcamps">
        <div class="col-md-12 m-t-50">

            <div class="title title2 text-center">
                <h3 class="subtitle-content m-b-40">
                    Programas de formación
                </h3>

            </div>

            <div class="box-content">
                <p class="text-parrafo">
                    Desarrollamos programas que fomenten el potencial del talento digital de nuestros socios y de sus
                    equipos de trabajo.
                </p>
                <div class="m-t-50 text-center">
                    <a href="https://forms.gle/nfGoJBduSjZnwecb8" target="_blank" class=" btn btn-default ">
                        MÁS INFORMACIÓN
                    </a>

                </div>

            </div>
        </div>
    </div>

    <div class="container">
        <div class="col-md-12 m-t-50">

            <div class="title title2 text-center">
                <h3 class="subtitle-content m-b-40">
                    Plataforma Despega tu E-commerce
                </h3>
                <img src="assets/images/citec/despega.svg" class="img-fluid text-center m-b-50" alt="despega">

            </div>

            <div class="box-content">
                <p class="text-parrafo">
                    Buscamos impulsar a las MiPymes del Ecuador mediante la transferencia de conocimientos y
                    herramientas de e-commerce de manera sencilla y gratuita. Creamos la plataforma Despega tu
                    E-commerce que impulsa el comercio electrónico capacitando a los usuarios en esta línea. Además,
                    cuenta con un directorio de soluciones e-commerce que brinda mayor visibilidad a las empresas socias
                    que ofrecen estos servicios.
                </p>
                <div class="m-t-50 text-center">
                    <a href="https://www.despegatuecommerce.citec.com.ec/" target="_blank" class=" btn btn-default ">
                        ACCEDE A LA PLATAFORMA
                    </a>

                </div>
            </div>
        </div>
    </div>

</section>
<app-afiliacion></app-afiliacion>

<app-footer></app-footer>
<app-copyright></app-copyright>