<!-- Nav Start-->
<header class="yoga loding-header nav-abs custom-scroll header-rel">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <nav class="navbar-expand-lg theme-nav w-100">
                    <a class="logo-responsive navbar-brand" href="yoga_layout.html"><img
                            alt="logo" class="logo-abs h-auto" src="assets/images/logo/1.png"></a>
                    <app-center-menu class="w-100"></app-center-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Nav end-->