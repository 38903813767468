import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-beneficios',
  templateUrl: './beneficios.component.html',
  styleUrls: ['./beneficios.component.scss']
})
export class BeneficiosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  alianzas = [
    { img: "assets/images/citec/beneficios/alianzas.jpg" },
 
   
  ]
  carouselAlianzas = {
    items: 2,
    autoHeight: false,
    nav: false,
    dots: true,
    autoplay: true,
    slideSpeed: 500,
    paginationSpeed: 500,
    loop: true,
    responsive: {
      0: {
        items: 1,
        margin: 30
      },
      576: {
        items: 1,

      },
      768: {
        items: 1,

      },
      1200: {
        items: 1,
        margin: 30
      }
    }
  }
}



