<!--copyright start-->
<div class="agency copyright ">
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <div class="link-horizontal align-items-center">
                    <ul class="socials-horizontal">
                        <li>
                            <a class="copyright-text" href="mailto:info@citec.com.ec">info@citec.com.ec</a>
                        </li>
                        <li>
                            <a href="https://api.whatsapp.com/send/?phone=593989348227&text=Hola! necesito información.."
                                target="_blank" class="copyright-text spacing m-r-5">(593-9) 89 348 227</a>
                            <a href="https://api.whatsapp.com/send/?phone=593987654074&text=Hola! necesito información.."
                                target="_blank" class="copyright-text spacing"> (593-9) 87 654 074</a>
                        </li>
                        
                    </ul>
                </div>

            </div>
            <div class="col-sm-5">
                <div class="link-horizontal">
                    <ul class="socials-horizontal">
                        <li class="icon-social">
                            <a href="https://www.instagram.com/citecec/" target="_blank"><i class="fa fa-instagram"
                                    aria-hidden="true"></i></a>
                        </li>
                        <li class="icon-social">
                            <a href="https://www.facebook.com/CamaraCITEC/" target="_blank"><i
                                    class="fa fa-facebook-f fa-inverse fa-2x"></i></a>
                        </li>

                        <li class="icon-social">
                            <a href="https://twitter.com/CITECec" target="_blank"><i class="fa fa-twitter"
                                    aria-hidden="true"></i></a>
                        </li>
                        <li class="icon-social">
                            <a href="https://www.linkedin.com/company/citecec/" target="_blank"><i
                                    class="fa fa-linkedin" aria-hidden="true"></i></a>
                        </li>
                        <li class="icon-social">
                            <a href="https://www.youtube.com/@citecec" target="_blank"><i
                                    class="fa fa-youtube" aria-hidden="true"></i></a>
                        </li>
                    </ul>

                </div>

            </div>
            <div class="col-sm-3">
                <div class="link-horizontal">


                    <ul>
                        <li class="footer-social-list">
                           
                            <a href="https://catalogoservicios.citec.com.ec" class="copyright-text text-white pull-right font-12">CATÁLOGO DE SERVICIOS</a>
                        </li>
                        <li class="footer-social-list">

                            <a routerLink="/afiliacion"  class="copyright-text text-white text-right font-12">AFÍLIATE</a>

                        </li>

                    </ul>
                </div>
            </div>
        </div>


    </div>
</div>
<div class="container-fluid">
    <div class="row" style="background-color: #1F035B;">
        <div class="col-md-12 m-b-5 p-t-5 text-center">
         
                <div class="text-white  text-logan">
                    Diseñado con 
                    <span>                        
                        <i class="fa fa-heart text-danger"> </i>
                    </span>
                     por Brandon Logan                    
                    <span>
                        <img src="assets/images/citec/creditos/logan.svg" alt="">
                    </span>
                </div>
         
        </div>
    </div>
</div>
<!--copyright start-->