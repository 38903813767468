<section style="padding: 0px !important;">
    <div class="container-fluid  " style="padding: 0px;">
        <img src="assets/images/citec/hero-imagen.svg" class="img-fluid w-100" alt="">
    </div>


</section>
<!-- client section -->
<section id="client-box" class="saas1 client brand-slider">

    <div class="container-fluid p-0">

        <div class="row">
            <div class="col-md-12">
                <h2 class="title-light m-b-30">Socios</h2>
            </div>
            <div class=" col-md-12  text-center">
                <owl-carousel-o [options]="clientcarouselOptions" class="">
                    <ng-container *ngFor="let client of clients">
                        <ng-template carouselSlide>
                            <img [src]="client.img" alt="client-slider" class="img-fluid">
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<section class="services" id="service" style="padding: 0px;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="title">
                    <div class="main-title">
                        <img src="assets/images/citec/membresia.svg" class="img-fluid" alt="">
                        <h2 class="type-membre m-t-50">Tipos de membresía</h2>
                    </div>
                    <div class="sub-title">
                        <p class="description-membre">Contrata la membresía que mejor se adapta al tamaño de tu
                            compañía.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-lg-6 col-sm-12 m-b-10">
                <div class="service  trimestral align-items-center">
                    <div
                        style="position: absolute; text-align: center; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                        <div class="service-feature">
                            <h4 class="feature-text text-white title-membresia">MEMBRESÍA</h4>
                            <p class="text-white text-trimestral">Trimestral</p>
                        </div>
                        <div class="m-t-25">
                            <a routerLink="/membership/trimestral"  class="btn btn-white ">
                                ELEGIR MEMEBRESÍA
                            </a>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12 m-b-10">
                <div class="service anual div-membresia-anual">
                    <div class="start-items">
                        <i class="fa fa-star color-start"></i>
                        <i class="fa fa-star color-start"></i>
                        <i class="fa fa-star color-start"></i>
                        <i class="fa fa-star color-start"></i>
                        <i class="fa fa-star color-start"></i>
                        <p class="text-start">MÁS POPULAR</p>
                    </div>
                    <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                        <div class="service-feature">
                            <h4 class="feature-text title-membresia2">MEMBRESÍA</h4>
                            <p class="text-anual center">Anual</p>

                        </div>
                        <div class="m-t-25">
                            <a routerLink="/membership/anual"  class=" btn btn-default ">ELEGIR
                                MEMEBRESÍA</a>

                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>

</section>
<!-- pricing section Start-->

<section class="app1 pricing mb-0" id="plan">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                <div class="title title1">
                    <div class="main-title">
                        <h2 class="title-light">Directorio</h2>
                    </div>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center">
                <img src="assets/images/citec/directorio.png" alt="" class="img-fluid">
            </div>
        </div>
    </div>
</section>
<section class="app1 pricing mb-0" id="plan" style="padding-top: 0px !important;">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                <div class="title title1">
                    <div class="main-title">
                        <h2 class="type-membre m-t-50">Equipo CITEC</h2>
                    </div>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center">
                <img src="assets/images/citec/equipo.png" alt="" class="img-fluid">
            </div>
        </div>
    </div>
</section>

<!-- pricing section end-->




<!-- testimonial section Start-->
<section class="yoga testimonial bg img-1">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="title-light">Testimonios</h2>

            </div>
            <div class="col-lg-10 offset-lg-1">
                <owl-carousel-o [options]="testimonalCarousel" class="trainers-slider">
                    <ng-container *ngFor="let testimonial of testimonials">

                        <ng-template carouselSlide class="item">

                            <div class="text-center testimonial-info border-testimonial"
                                style="margin-top: 50px; min-height: 392px;">
                                <img [src]="testimonial.img" alt="" class="img-fluid m-auto"
                                    style="margin-top: -40px !important; border-radius: 50px; width: 80px !important; height: 80px !important;">
                                <p class="testimonial-para ">
                                    <span class="color-black">
                                        {{testimonial.comment}}
                                    </span>
                                    {{testimonial.comment2}}
                                </p>
                                <div class="containt-author">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h4 class="autor-testimonio">{{testimonial.author}}</h4>
                                            <h6 class="subtitle-testimonio">{{testimonial.company}}</h6>
                                        </div>
                                        <div class="col-md-4">
                                            <img [src]="testimonial.logoCompany" alt="logoCompany"
                                                style="height: 57px; float: right">
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- testimonial section end-->
<section id="contact">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="title title2">
                    <img src="assets/images/citec/suscribete.svg" class="img-fluid" alt="" style="width: 50%;">
                    <h3 class="title-comunidad">Únete a la <span class="title-light"> comunidad</span> </h3>
                    <p class="ubscribe-sub-head  container-text m-t-25">
                        Sé parte de la comunidad CITEC
                    </p>
                    <div class="text-center m-t-25">
                        <a routerLink="/afiliacion" class="btn btn-default btn-unir-slack">UNIRME A CITEC</a>
                    </div>

                </div>
              

            </div>
            

        </div>
    </div>
</section>
<!-- <section>

    <div class="container m-t-50">
        <div class="row">
            <div class="col-md-12 ">

                <div class="contenedor ">
                    <img src="assets/images/citec/group126.svg" class="img-fluid" />

                    <div class="centrado text-section3">Somos la voz de los empresarios tecnológicos en Ecuador 🇪🇨
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>  -->

<section style="padding: 0px !important;">
    <div class="bg-margin">
        <div class="container-fluid bg-image" style="background-image: url(assets/images/citec/group126.svg);">
            <div class="row align-items-center">
                <div class="col-12 text-center">
                    <div class="text-center title-afiliacion-ec">
                        Somos la voz de los empresarios tecnológicos en Ecuador <span><img
                                src="assets/images/citec/ec.png" alt=""> </span>
                    </div>
                    <div class="col-sm-12 col-md-6  col-lg-6 offset-lg-3  offset-md-3 text-center">
                        <p class="text-white container-text">
                            Elevamos las necesidades de nuestros socios a los tomadores de decisión para generar
                            políticas que beneficien al sector.
                        </p>
                    </div>

                    <div class="m-t-40 m-t-40 mb-bt-afiliacion">
                        <a routerLink="/afiliacion" class="btn btn-white ">
                            INICIAR PROCESO DE AFILIACIÓN
                        </a>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="row">
        <div class="col-md-3">
            <div class="border-alianzas">
                <p class="alianzas">
                    <span class="alianzas-bold ">+ de 230 </span>

                    Socios
                </p>
            </div>
        </div>
        <div class="col-md-3">
            <div class="text-center">
                <img src="assets/images/citec/alianza/socios.svg" alt="">
            </div>
        </div>
        <div class="col-md-6">
            <div class="border-alianzas">
                <h1 class="alianzas"> <span class="alianzas-bold">+ de 50 </span> <br> oportunidades de negocio
                    socializadas anualmente</h1>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="border-alianzas">
                <p class="alianzas">
                    Alianza con <span class="alianzas-bold">20 </span> universidades a nivel nacional e internacional
                </p>
            </div>
        </div>
        <div class="col-md-2">
            <div class="text-center">
                <img src="assets/images/citec/alianza/alianza.svg" alt="">
            </div>
        </div>
        <div class="col-md-4">
            <div class="border-alianzas">
                <p class="alianzas"><span class="alianzas-bold">2 </span> clústers de tecnología co-incubados</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="border-alianzas">
                <p class="alianzas"> <span class="alianzas-bold"> + de 10</span> reformas
                    normativas y políticas <br>
                    públicas del sector TIC</p>
            </div>
        </div>
        <div class="col-md-2">
            <div class="text-center">
                <img src="assets/images/citec/alianza/espectadores.svg" alt="">
            </div>
        </div>
        <div class="col-md-6">

            <div class="border-alianzas">
                <p class="alianzas">
                    <span class="alianzas-bold">+ de 20 </span> <br> CITECH TALKS realizados con promedio de 20.000
                    espectadores por programa realizados mensualmente
                </p>
            </div>
        </div>
    </div>
</section>