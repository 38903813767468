<app-header></app-header>
<section>



    <div class="col-md-12">

        <div class="text-center">
            <h3 class="title-beneficios m-b-50 ">Protección <span class="title-comunidad">
                    de datos</span>
            </h3>
        </div>
    </div>

    <div class="container">
        <div class="col-md-12">
            <div class="text-center">
                <img src="assets/images/citec/capacitacion/capacitacion.svg" width="45%" class="img-fluid" alt="">
            </div>



        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title2">
                    <h3 class="subtitle-content m-b-50 m-t-50">¿Buscas una solución para implementar la Ley de
                        protección de datos?</h3>
                    <p class="text-parrafo" style="text-align: center !important;">
                        ¡Aquí te presentamos a las mejores empresas para realizarlo!
                    </p>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-sm-12 col-md-3 col-lg-3" *ngFor="let company of companyList">
                <div class="boxCard">
                    <div class="box-card-inner">
                        <div class="box-card-front d-flex align-items-center justify-content-center">
                            <div class="m-2">
                                <img [src]="company.logo" alt="logo" class="w-100 img-fluid" style="max-height: 320px;">
                            </div>
                        </div>
                        <div class="box-card-back">
                            <div class="data-title">{{company.name}}</div>
                            <p class="m-t-20 scroll-content">
                                {{company.content}}
                            </p>
                            <div class="socials-lists  fixed-element">
                                <ul class="socials-horizontal justify-content-center">
                                    <li>
                                        <a href="{{company.email}}">
                                            <i class="fa fa-envelope center-content" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="{{company.whatsapp}}" target="_blank">
                                            <i class="fa fa-whatsapp center-content" aria-hidden="true"></i>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>



</section>
<app-afiliacion></app-afiliacion>
<app-footer></app-footer>
<app-copyright></app-copyright>