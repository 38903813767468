import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cursos',
  templateUrl: './cursos.component.html',
  styleUrls: ['./cursos.component.scss']
})
export class CursosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  eventos = [
    { img: "assets/images/citec/capacitacion/eventos/event1.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event2.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event3.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event4.png" },
    { img: "assets/images/citec/capacitacion/eventos/event5.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event6.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event7.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event8.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event9.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event10.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event11.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event12.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event14.png" },
    { img: "assets/images/citec/capacitacion/eventos/event15.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event16.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event17.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event18.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event19.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event20.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event21.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event22.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event23.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event24.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event25.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event26.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event27.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event28.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event29.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event30.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event31.png" },
    { img: "assets/images/citec/capacitacion/eventos/event32.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event33.png" },
    { img: "assets/images/citec/capacitacion/eventos/event34.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event35.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event36.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event37.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event38.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event39.jpg" },
    { img: "assets/images/citec/capacitacion/eventos/event40.jpg" },

  ]
  carouselEvent = {
    items: 40,
    autoHeight: true,
    nav: false,
    dots: true,
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
      0: {
        items: 1,
        margin: 30
      },
      576: {
        items: 1,
        margin: 30

      },
      768: {
        items: 1,
        margin: 30

      },
      1200: {
        items: 1,
        margin: 30
      }
    }
  }
  formacion = [
    { img: "assets/images/citec/capacitacion/formacion/Bootcamp1.jpg" },
    { img: "assets/images/citec/capacitacion/formacion/Bootcamp2.jpg" },
    { img: "assets/images/citec/capacitacion/formacion/Bootcamp3.png" },
    { img: "assets/images/citec/capacitacion/formacion/Bootcamp4.jpg" },
    { img: "assets/images/citec/capacitacion/formacion/Bootcamp5.jpg" },
    { img: "assets/images/citec/capacitacion/formacion/Bootcamp6.jpg" },
  ]
  carouselFormation = {
    items: 40,
    autoHeight: true,
    nav: false,
    dots: true,
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
      0: {
        items: 1,
        margin: 30
      },
      576: {
        items: 1,

      },
      768: {
        items: 1,

      },
      1200: {
        items: 1,
        margin: 30
      }
    }
  }
}
