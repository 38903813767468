<app-header></app-header>
<section>


    <div class="row">
        <div class="col-md-12">
            <div class="text-center">
                <h3 class="title-beneficios m-b-50">Recursos
                </h3>
            </div>
        </div>

        <div class="container">
            <div class="col-md-12 ">
                <div class="text-center">
                    <img src="assets/images/citec/recursos.svg" class="img-fluid" alt="">
                </div>

                <div class="col-md-12">
                    <div class="title title2">
                        <h3 class="subtitle-content m-b-50">Estudios y generación de data del sector</h3>

                    </div>
                </div>


            
            <div class="box-content">
            <div class="row">
                <div class="col-md-6 m-b-30">
                    <div class="card border-recurso">
                        <div class="card-body text-center space-border">
                            <p class="bold-title">
                                E-commerce
                            </p> <br>
                            <a href="assets/documents/estudio_ecommerce.pdf" target="_blank" class="btn btn-default  m-b-50">
                                Descargar
                            </a>

                        </div>
                    </div>
                </div>
                <div class="col-md-6  m-b-30">
                    <div class="card border-recurso">
                        <div class="card-body text-center space-border">
                            <p class="bold-title">
                                Fin Tech
                            </p>
                            <br>
                            <a href="assets/documents/estudio_fintech.pdf" target="_blank"
                                class="btn btn-default  m-b-50">
                                Descargar
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 m-b-30">
                    <div class="card border-recurso">
                        <div class="card-body text-center space-border">
                            <p class=" bold-title">
                                Plataformas digitales
                            </p>
                            <br>
                            <a href="assets/documents/estudio_impacto_ecuador.pdf" target="_blank"
                                class="btn btn-default  m-b-50">
                                Descargar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>

        <div class="container">
            <div class="col-md-12">

                <div class="col-md-10 offset-md-1">
                    <div class="title title2">
                        <h3 class="subtitle-content m-b-40 m-t-30">Documentos legales estandarizados</h3>

                    </div>

                </div>
                <div class="box-content">
                    <p class="text-parrafo m-b-30">Pensando en las necesidades del día a día de nuestras empresas,
                        hemos
                        creado esta compilación de documentos relevantes:
                    </p>
                    <div class="row">
                        <div class="col-md-6 m-b-30">
                            <div class="card border-recurso">
                                <div class="card-body text-center space-border">
                                    <p class=" bold-title m-b-45">
                                        NDA estándar para socios CITEC
                                    </p>



                                    <a href="assets/documents/nda_estandar.pdf" target="_blank"
                                        class="btn btn-default  m-b-50">
                                        Descargar
                                    </a>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-6  m-b-30">
                            <div class="card border-recurso">
                                <div class="card-body text-center space-border">
                                    <p class=" bold-title">
                                        Cláusula de uso o cesión de propiedad intelectual
                                    </p>
                                    <br>
                                    <a href="assets/documents/clausula_propiedad_intelectual.pdf" target="_blank"
                                        class="btn btn-default  m-b-50">
                                        Descargar
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 m-b-30">
                            <div class="card border-recurso">
                                <div class="card-body text-center space-border">
                                    <p class=" bold-title">
                                        Cláusula de uso de imagen
                                    </p>
                                    <br>
                                    <a href="assets/documents/clausula_uso_imagen.pdf" target="_blank"
                                        class="btn btn-default  m-b-50">
                                        Descargar
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
        </div>
        <div class="container">
            <div class="col-md-12">


                <div class="col-md-12">
                    <div class="title title2">
                        <h3 class="subtitle-content m-b-40 m-t-30">Generación de data/ datos del sector</h3>

                    </div>

                </div>
                <div class="box-content">
                    <p class="text-parrafo m-b-30">
                        En esta sección podrás encontrar contenido actualizado y relevante sobre el sector tecnológico y
                        de innovación a nivel nacional.

                    </p>
                    <p class="text-parrafo m-b-30">
                        Si eres socio, comunícate al correo proyectos@citec.com.ec para acceder a esta información.
                    </p>
                    <div class="row">
                        <div class="col-md-6 m-b-30">
                            <h3 class="subtitle-content m-b-40 m-t-30">
                                Biblioteca de eventos
                            </h3>
                            <div class="card border-recurso">
                                <div class="card-body text-center space-border">
                                    <p class=" bold-title">
                                        Blog CITEC
                                    </p>
                                    <br>
                                    <a href="https://citec.com.ec/blog" target="_blank" class="btn btn-default  m-b-50">
                                        ACCEDE AL BLOG
                                    </a>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-6  m-b-30">
                            <h3 class="subtitle-content text-center m-b-40 m-t-30">Boletín <br> informativo</h3>
                            <div class="card border-recurso text-center">

                                <div class="card-body  space-border">
                                    <p class=" bold-title text-center">
                                        Revisar boletines anteriores
                                    </p>
                                    <br>
                                    <a href="https://bit.ly/Julio2023Boletin" target="_blank"
                                        class="btn btn-default  m-b-50">
                                        ACCEDE AL BOLETÍN
                                    </a>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div class="yoga subscribe bg img-1" id="contact">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12  col-md-12">
                            <div class="title title2">
                                <img src="assets/images/citec/suscribete.svg" class="img-fluid" alt="">
                                <h3 class="title-comunidad">Recibir newsletter <span class="title-beneficios">
                                        CITEC</span> </h3>
                                <p class="ubscribe-sub-head  container-text">
                                    Suscríbete a nuestro boletín informativo</p>
                            </div>
                        </div>
                        <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                            <div class="justify-content-center link-horizontal">
                                <div class="btn-toolbar" role="group">
                                    <iframe id='doppler_subscription'
                                        src='https://app2.fromdoppler.com/Lists/FormProcessing/PublishedForm?IdForm=PUGJYaVHHfJ%2buKcSne%2bDHw%3d%3d'
                                        height='335' width='100%' scrolling='yes' frameborder='no'
                                        style='overflow:scroll'>
                                    </iframe>



                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </div>


    </div>


</section>
<app-afiliacion></app-afiliacion>
<app-footer></app-footer>
<app-copyright></app-copyright>