import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-citec',
  templateUrl: './citec.component.html',
  styleUrls: ['./citec.component.scss']
})
export class CitecComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
