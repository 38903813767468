import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-membership-form',
  templateUrl: './membership-form.component.html',
  styleUrls: ['./membership-form.component.scss']
})
export class MembershipFormComponent implements OnInit {
  membership = ''
  formContact: any
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastr: ToastrService) {

  }

  ngOnInit(): void {
    this.membership = this.route.snapshot.paramMap.get('tipo');

    this.formContact = this.formBuilder.group({
      nombre: ['', [Validators.required, Validators.maxLength(50), Validators.pattern('^\s*([a-zA-ZáéíóúAÉÍÓÚÑñ0-9 .,!?¡=¿-]+)\s*$')]],
      email: ['', [Validators.required, Validators.email]],
      telefono: ['', [Validators.required, Validators.maxLength(30), Validators.pattern('^[0-9]*$')]],
      mensaje: ['', [Validators.required, Validators.pattern('^\s*([a-zA-ZáéíóúAÉÍÓÚÑñ0-9 .,!?¡=¿-]+)\s*$')]],
      membresia: [this.membership, [Validators.required]]
    });
  }

  showSuccess() {
    this.toastr.success('Pronto nos prodremos en contacto.', 'Enviado con éxito!');
  }
  showError() {
    this.toastr.error('Revise los campos.', 'Formulario inválido!');
  }
  onSummit() {
    if (this.formContact.valid) {
      //this.showSuccess()
      this.toastr.error('No hemos podido enviar los datos', 'Error');
      //this.formContact.reset();
      console.log('Formulario válido.', this.formContact.value);
    } else {
      this.showError()
      console.log('Formulario inválido.', this.formContact.value);
    }
  }
}
