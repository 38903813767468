import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comunidad',
  templateUrl: './comunidad.component.html',
  styleUrls: ['./comunidad.component.scss']
})
export class ComunidadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  talk = [
    {
      img: "assets/images/citec/capacitacion/talk/1.png",

    },
    {
      img: "assets/images/citec/capacitacion/talk/2.png",

    },
  
    {
      img: "assets/images/citec/capacitacion/talk/3.png",
    },
    {
      img: "assets/images/citec/capacitacion/talk/4.png",
    },
    {
      img: "assets/images/citec/capacitacion/talk/5.png",

    },
   

  ]
  carouselTalk = {
    items: 11,
    autoHeight: true,
    nav: false,
    dots: true,
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,


    responsive: {
      0: {
        items: 1,
        margin: 30
      },
      576: {
        items: 1,
        margin: 30

      },
      768: {
        items: 1,
        margin: 30

      },
      1200: {
        items: 1,
        margin: 30
      }
    }
  }

}
