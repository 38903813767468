import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
  path?: string;
  title?: string;
  type?: string;
  icon?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  megaMenu?: boolean;
  megaMenuType?: string; // small, medium, large
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})
export class NavService {

  constructor() { }

  MENUITEMS: Menu[] = [
    {
      title: 'Beneficios', type: 'sub', path: '/beneficios',
    },
    {
      title: 'Comunidad', type: 'sub', path: '/comunidad'

    },
   
    {
      title: 'Cursos gratuitos', type: 'sub', path: '/cursos'
    },
    {
      title: 'Recursos', type: 'sub', path: '/recursos'
    },
    {
      title: 'Protección de datos', type: 'sub', path: '/proteccion'
    }, 

  ]

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

}
